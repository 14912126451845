import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as auth from "../_redux/authRedux";
import { login, resend_confirmation } from "../_redux/authCrud";
import './auth.scss';
import { Checkbox } from "../../../../_metronic/_partials/controls";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  // console.log(props)
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setStatus('');
      // console.log('ddd')
      login({ email: values.email, password: values.password })
        .then((response) => {
          disableLoading();
          setSubmitting(false);
          var data = response.data;
          if (data.status === 'Success') {
            props.login(data.data.access_token);
          }
        })
        .catch((error) => {
          var message = error.response.data.message;
          var status_code = error.response.status;
          if (status_code === 412) {
            var bodydd = {};
            bodydd['email'] = values.email;
            resend_confirmation(bodydd)
              .then((response) => {
                var data = response.data;
                disableLoading();
                setSubmitting(false);
                if (data.status === 'Success') {
                  props.history.push({
                    pathname: '/auth/activate',
                    state: {
                      email: values.email,
                      password: values.password
                    }
                  })
                } else {
                  setStatus(data.error)
                }
              })
              .catch((error) => {
                disableLoading();
                setSubmitting(false);
                var message = error.response.data.message;
                var status_code = error.response.status;
                console.log(status_code);
                setStatus(`${message}`)
              });

          } else {
            disableLoading();
            setSubmitting(false);

            setStatus(`${message}`)
          }

        });
    },


  });


  return (
    <div className="login-form login-signin custom-login col-xl-4 col-lg-4" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-14">
        <h3 className="view-title">Login</h3>
      </div>
      {/* <button className="d-flex align-items-center justify-content-center google-btn">
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/misc/google.png")}
        />
        <span>Login with Google</span>
      </button> */}
      {/* <div className="divider-or">
        <div className="horizontal-line" />
        <span>or</span>
      </div> */}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework w-100"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          null
        )}

        <div className="form-group fv-plugins-icon-container">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            className={`form-control h-auto py-4 px-6 ${getInputClasses("email")}`}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container" style={{ marginBottom: '8px' }}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label htmlFor="password">Password</label>
            <button className="show-password" type="button">Show password</button>
          </div>
          <input
            type="password"
            name="password"
            className={`form-control h-auto py-4 px-6 ${getInputClasses("password")}`}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>

        <div className="form-group d-flex flex-wrap align-items-center">
          <Checkbox />
          <span className="ml-2" style={{ color: '#181819E5', fontSize: '14px', fontWeight: '500' }}>Keep me logged in</span>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            // type="button"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold my-3 w-100 py-3"
            // onClick={() => props.history.push(`/dashboard`)}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}
// "/dashboard"
export default injectIntl(connect(null, auth.actions)(Login));

import React from 'react'
import './extras.scss'

export default function OffCanvas({ children, handleClose, show }) {
  if (!show) return null
  return (
    <div className="side-panel">
      <div className="side-panel-overlay" onClick={handleClose} />
      <div className="side-panel-body">
        {children}
      </div>
    </div>
  )
}

import React from 'react'

export default function Estate({ loading, estateFields, generalFields, handleGeneralForm, handleEstateForm, changeEstateInfo }) {
  return (
    <div className="settings-form">
      <h6>Estate infromation</h6>
      <div className="form-content no-border">
        <div className="fields">
          <div className="form-group">
            <input className="modal-input" placeholder="Estate name" type="text"
              value={estateFields.name} onChange={(e) => handleEstateForm('name', e.target.value)} />
            <input className="modal-input" placeholder="Email" type="email" disabled
              value={estateFields.email} onChange={(e) => handleEstateForm('email', e.target.value)} />
          </div>

          <div className="form-group">
            <input className="modal-input" placeholder="State" type="text" disabled
              value={estateFields.phone} onChange={(e) => handleEstateForm('phone', e.target.value)} />
            <input className="modal-input" placeholder="Address" type="text"
              value={estateFields.address} onChange={(e) => handleEstateForm('address', e.target.value)} />
          </div>
        </div>
        {/* <div className="image-upload">
          <img src={toAbsoluteUrl('/media/misc/upload-picture.svg')} className="item-icon" />
          <button>Upload</button>
        </div> */}
      </div>
      <h6>Contact Person</h6>
      <div className="form-content">
        <div className="fields">
          <div className="form-group">
            <input className="modal-input" placeholder="Full name" type="text" disabled
              value={generalFields.fullname} onChange={(e) => handleGeneralForm('fullname', e.target.value)} />
            <input className="modal-input" placeholder="Phone" type="text" disabled
              value={generalFields.phone} onChange={(e) => handleGeneralForm('phone', e.target.value)} />
          </div>

          <div className="form-group">
            <input className="modal-input" placeholder="Email" type="email" disabled
              value={generalFields.email} onChange={(e) => handleGeneralForm('email', e.target.value)} />
          </div>
        </div>
      </div>

      <div className="submit-changes">
        <button className="primary" onClick={changeEstateInfo}>{loading ? 'Please wait...' : 'Save'}</button>
      </div>
    </div>
  )
}

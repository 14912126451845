import moment from "moment";



export  function getTimeDifference(date) {
    const now = moment();
    const dddd = moment(date);
    const diff = moment.duration(dddd.diff(now));
    console.log(date);
   
    if (diff.asMinutes() < 60) {
      const minutes = Math.round(diff.asMinutes());
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
      const hours = Math.round(diff.asHours());
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    }
}

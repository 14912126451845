import * as requestFromServer from "./Crud";
import { SettingsSlice, callTypes } from "./Slice";


const { actions } = SettingsSlice;


export const fetchUserProfile = (userId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserProfile(userId)
    .then(response => {
      var res = response.data;
      dispatch(actions.userData(res.data || null));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEstateUsers = (estateId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getEstateUsers(estateId)
    .then(response => {
      var res = response.data;
      dispatch(actions.estateUsers(res.data || null));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEstateProfile = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getEstateProfile()
    .then(response => {
      var res = response.data;
      dispatch(actions.estateData(res.data || null));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInviteeByEmail = (email) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserByEmail(email)
    .then(response => {
      var res = response.data;
      dispatch(actions.inviteeDetails(res.data || null));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find User";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const InviteUser = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .sendUserInvite(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addUser = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addUserToEstate(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't add user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const editUserInfo = (payload, userId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .putUserInfo(payload)
    .then(response => {
      var res = response.data;
      dispatch(fetchUserProfile(userId))
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const editEstateInfo = (payload, estateId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .putEstateInfo(payload, estateId)
    .then(response => {
      var res = response.data;
      dispatch(fetchEstateProfile());
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const forgotPasswordInit = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .passwordChangeInit(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const forgotPasswordFinal = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .passwordChangeFinal(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
import React from "react";

export function Checkbox({ isSelected, onChange, children }) {
  return (
    <>
      <input type="checkbox" style={{ display: "none" }} />
      <label className="checkbox checkbox-single" style={{ border: '2px solid #919294', borderRadius: '2px', width: '16px', height: '16px' }}>
        <input type="checkbox" checked={isSelected} onChange={onChange} />
        {children}
        <span style={{ backgroundColor: 'transparent' }} />
      </label>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import AccessModal from "../../../../_metronic/layout/components/AccessModal";
import { Estate, General, Users, Payment } from "../components";
import {
  fetchUserProfile, fetchEstateProfile, fetchEstateUsers, InviteUser, editUserInfo,
  editEstateInfo, forgotPasswordInit, forgotPasswordFinal, fetchInviteeByEmail, addUser
} from '../_redux/Actions';
import './settings.scss';

export function Residents(props) {
  const dispatch = useDispatch();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePasswordEmailModal, setChangePasswordEmailModal] = useState(false);
  const [activeTab, setActiveTab] = useState('general');
  const [formFields, setFormFields] = useState({});
  const [fields, setFields] = useState({});
  const [generalFields, setGeneralFields] = useState({});
  const [estateFields, setEstateFields] = useState({});
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [currEmail, setCurrEmail] = useState('');
  const [addCardInitModal, setAddCardInitModal] = useState(false);
  const [addCardFormModal, setAddCardFormModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [forgotPasswordFields, setForgotPasswordFields] = useState({});

  const { loading, userDetails, estateDetails, estateUsers, user } = useSelector(
    (state) => ({
      loading: state.settings.listLoading,
      user: state.auth.user,
      userDetails: state.settings.userDetails,
      estateDetails: state.settings.estateDetails,
      estateUsers: state.settings.estateUsers,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!userDetails || !userDetails?.user_id) {
      dispatch(fetchUserProfile(user.user_id));
    } else {
      const ffd = { ...setGeneralFields }
      ffd.firstname = userDetails?.first_name
      ffd.lastname = userDetails?.last_name
      ffd.fullname = `${userDetails?.first_name} ${userDetails?.last_name}`
      ffd.email = userDetails?.email
      ffd.phone = userDetails?.phone_number
      setGeneralFields(ffd);
    }
  }, [userDetails])

  useEffect(() => {
    if (!estateDetails || !estateDetails?.zone) {
      dispatch(fetchEstateProfile());
    } else {
      dispatch(fetchEstateUsers(estateDetails?.zone?.estate?._id))
      const ffd = { ...estateFields }
      ffd.name = `${estateDetails?.zone?.estate?.name}`
      ffd.email = estateDetails?.zone?.estate?.email
      ffd.phone = `${estateDetails?.zone?.estate?.phoneNumber.countryCode} ${estateDetails?.zone?.estate?.phoneNumber.number}`
      ffd.address = estateDetails?.zone?.estate?.address
      setEstateFields(ffd);
    }
  }, [estateDetails])

  const handleGeneralForm = (field, value) => {
    const oldform = { ...generalFields };
    oldform[field] = value;
    setGeneralFields(oldform);
  }

  const handleChange = (field, value) => {
    const oldform = { ...fields };
    oldform[field] = value;
    setFields(oldform);
  }

  const handleEstateForm = (field, value) => {
    const oldform = { ...estateFields };
    oldform[field] = value;
    setEstateFields(oldform);
  }

  const handleForgotPasswordChange = (field, value) => {
    const oldform = { ...forgotPasswordFields };
    oldform[field] = value;
    setForgotPasswordFields(oldform);
  }

  const inviteNewUser = () => {
    dispatch(fetchInviteeByEmail(inviteeEmail)).then((res) => {
      if (res?.data?.email) {
        const payload = {
          userId: res?.data?._id,
          zoneId: estateDetails.zone._id,
        };
        dispatch(addUser(payload)).then((res) => {
          if (res.status == 'Success') {
            setInviteeEmail('');
            setAddUserModal(false);
            dispatch(fetchEstateUsers(estateDetails?.zone?.estate?._id))
            toast.success("User has been added successfully.");
          }
        }).catch(() => {
          toast.error('Please try again later')
        });
      } else {
        const payload = {
          estateId: estateDetails?.zone.estate?._id,
          email: inviteeEmail,
          inviteType: "user",
          zoneId: estateDetails.zone._id,
        };
        dispatch(InviteUser(payload)).then((res) => {
          if (res.status == 'Success') {
            setInviteeEmail('');
            toast.success("An invitation have been successfully sent");
            setAddUserModal(false);
          }
        }).catch(() => {
          toast.error('Please try again later')
        });
      }
    })
  }

  const editInfo = () => {
    const payload = {
      first_name: generalFields.firstname,
      last_name: generalFields.lastname,
    };
    dispatch(editUserInfo(payload, user.user_id)).then((res) => {
      if (res.status == 'Success') {
        toast.success("Updated user details");
      }
    }).catch(() => {
      toast.error('Please try again later')
    });
  }

  const changeEstateInfo = () => {
    const payload = {
      name: estateFields.name,
      address: estateFields.address,
    };
    dispatch(editEstateInfo(payload, estateDetails?.zone?.estate?._id)).then((res) => {
      if (res.status == 'Success') {
        toast.success("Updated estate details");
      }
    }).catch(() => {
      toast.error('Please try again later')
    });
  }

  const initPasswordChange = () => {
    const payload = {
      email: currEmail || userDetails?.email,
    };
    dispatch(forgotPasswordInit(payload)).then((res) => {
      if (res.status == "Success") {
        setChangePasswordEmailModal(false)
        setChangePasswordModal(true);
      } else {

      }
    });
  }

  const finalPasswordChange = () => {
    if (forgotPasswordFields.password == forgotPasswordFields.password2) {
      const payload = {
        email: userDetails?.email,
        code: forgotPasswordFields.code,
        new_password: forgotPasswordFields.password
      };
      dispatch(forgotPasswordFinal(payload)).then((res) => {
        if (res?.status == "Success") {
          toast.success("Success");
          setChangePasswordModal(false);
        } else {
          toast.error('Unsuccessful. Please retry later')
        }
      });
    } else {
      toast.warn("Passwords do not match");
    }
  }


  return (
    <>
      <div className="settings-container overflow-hidden">
        <div className="tabs">
          <button className={`${activeTab == 'general' && 'active'} tab`} onClick={() => setActiveTab('general')}>General</button>
          <button className={`${activeTab == 'estate' && 'active'} tab`} onClick={() => setActiveTab('estate')}>Estate</button>
          <button className={`${activeTab == 'payment' && 'active'} tab`} onClick={() => setActiveTab('payment')}>Payment</button>
          <button className={`${activeTab == 'users' && 'active'} tab`} onClick={() => setActiveTab('users')}>Users</button>
        </div>
        {activeTab === 'general' ? <General {...{ loading, generalFields, handleGeneralForm, setChangePasswordEmailModal, editInfo }} /> : null}
        {activeTab === 'estate' ? <Estate {...{ loading, estateFields, generalFields, handleGeneralForm, handleEstateForm, changeEstateInfo }} /> : null}
        {activeTab === 'payment' ? <Payment {...{ setAddCardInitModal }} /> : null}
        {activeTab === 'users' ? <Users  {...{ estateUsers, setChangeRoleModal, setRemoveUserModal, setAddUserModal }} /> : null}
      </div>
      <AccessModal
        show={changePasswordEmailModal}
        title="Change password"
        continueText="Submit"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setChangePasswordEmailModal(false)}
        handleContinue={() => initPasswordChange()}
      >
        <div className="field">
          <input type="email" className="modal-input" placeholder="Verify email address" disabled
            value={currEmail || userDetails?.email} onChange={(e) => setCurrEmail(e.target.value)} />
        </div>
      </AccessModal>
      <AccessModal
        show={changePasswordModal}
        title="Change password"
        continueText="Update"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setChangePasswordModal(false)}
        handleContinue={() => finalPasswordChange(false)}
      >
        <p>Reset Password instruction has been send to your email</p>
        <div className="field">
          <input type="text" className="modal-input" placeholder="Enter code"
            value={forgotPasswordFields.code} onChange={(e) => handleForgotPasswordChange('code', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
        <div className="field">
          <input type="password" className="modal-input" placeholder="New Password"
            value={forgotPasswordFields.password} onChange={(e) => handleForgotPasswordChange('password', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
        <div className="field">
          <input type="password" className="modal-input" placeholder="Re-enter new password"
            value={forgotPasswordFields.password2} onChange={(e) => handleForgotPasswordChange('password2', e.target.value)} />
          <img src={toAbsoluteUrl('/media/misc/eye-on.svg')} className="item-icon" />
        </div>
      </AccessModal>

      <AccessModal
        show={addCardInitModal}
        title="Add a new card"
        continueText="Continue"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddCardInitModal(false)}
        handleContinue={() => [setAddCardInitModal(false), setAddCardFormModal(true)]}
      >
        <p className="instruction">To add and verify your bank account, NGN 100.00 will be charged from your bank account.</p>
      </AccessModal>

      <AccessModal
        show={addCardFormModal}
        title="Add a new card"
        continueText="Continue"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddCardFormModal(false)}
        handleContinue={() => setAddCardFormModal(false)}
      >
        <div className="field">
          <input type="text" className="modal-input" placeholder="Card number"
            value={formFields.cardNumber} onChange={(e) => handleChange('cardNumber', e.target.value)} />
        </div>
        <div className="field">
          <input type="number" className="modal-input" placeholder="Expiry"
            value={formFields.expiry} onChange={(e) => handleChange('expiry', e.target.value)} />
        </div>
        <div className="field">
          <input type="text" className="modal-input" placeholder="CVV"
            value={formFields.cvv} onChange={(e) => handleChange('cvv', e.target.value)} />
        </div>
      </AccessModal>

      <AccessModal
        show={removeUserModal}
        title="Remove user"
        continueText="Remove"
        continueType="danger"
        cancelType="plain"
        handleClose={() => setRemoveUserModal(false)}
        handleContinue={() => setRemoveUserModal(false)}
      >
        <p className="instruction">James Zagadat will no longer be able to access your dashboard. Do you want to Proceed?</p>
      </AccessModal>

      <AccessModal
        show={changeRoleModal}
        title="Change role"
        continueText="Update"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setChangeRoleModal(false)}
        handleContinue={() => setChangeRoleModal(false)}
        contentStyle="manage-role"
      >
        <div className="change-role">
          <form className="change-role-form">
            <select>
              <option>Administrator</option>
              <option>Role D1</option>
              <option>Role D2</option>
              <option>Role D3</option>
            </select>
          </form>
          <ul>
            <li>
              <span className="key">Administrator:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D1:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D2:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D3:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
          </ul>
        </div>
      </AccessModal>

      <AccessModal
        show={addUserModal}
        title="Add user"
        continueText="Add user"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setAddUserModal(false)}
        handleContinue={() => inviteNewUser()}
        contentStyle="manage-role"
      >
        <div className="change-role">
          <form className="change-role-form">
            <input type="email" placeholder="User email" className="modal-input mb-8" value={inviteeEmail} onChange={(e) => setInviteeEmail(e.target.value)} />
            {/* <select>
              <option>Administrator</option>
              <option>Role D1</option>
              <option>Role D2</option>
              <option>Role D3</option>
            </select> */}
          </form>
          {/* <ul>
            <li>
              <span className="key">Administrator:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D1:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D2:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
            <li>
              <span className="key">Role D3:</span>
              <span className="description">Role description... pretium lacinia ac porta odio, quis facilisis lacus. Nunc porta, eros quis faucibus.</span>
            </li>
          </ul> */}
        </div>
      </AccessModal>
    </>
  );
}

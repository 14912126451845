import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import AccessModal from "../../../../_metronic/layout/components/AccessModal";
import OffCanvas from "../../../../_metronic/layout/components/OffCanvas";
import { fetchEstateProfile } from '../../Settings/_redux/Actions';
import { InviteResident, addResident, fetchInviteeByEmail, fetchResidents } from '../_redux/Actions';
import './residents.scss';

const accesses = [
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
]

export function Residents(props) {

  const dispatch = useDispatch();
  const [detailsOffCanvas, setDetailsOffCanvas] = useState(false);
  const [revokeAccessModal, setRevokeAccessModal] = useState(false);
  const [deleteResidentModal, setDeleteResidentModal] = useState(false);
  const [deleteResidentContinueModal, setDeleteResidentContinueModal] = useState(false);
  const [activeTab, setActiveTab] = useState('info');
  const [addSingleResidentModal, setAddSingleResidentModal] = useState(false);
  const [uploadResidentsModal, setUploadResidentsModal] = useState(false);
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [residentDetails, setResidentDetails] = useState(null);

  const { loading, estateDetails, residents } = useSelector(
    (state) => ({
      loading: state.settings.listLoading,
      estateDetails: state.settings.estateDetails,
      residents: state.residents.residents,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEstateProfile());
  }, [])

  useEffect(() => {
    if (estateDetails && estateDetails?.zone) {
      dispatch(fetchResidents(estateDetails?.zone.estate?._id))
    }
  }, [estateDetails])

  const maxString = (str, lgt) => {
    let res = str;
    if (str.length <= lgt) {
      return res
    }
    else {
      const strArr = str.split('');
      strArr.length = lgt;
      res = `${strArr.join('')}...`;
    }
    return res;
  }

  const openResidentDetails = (data) => {
    setResidentDetails(data);
    setDetailsOffCanvas(true);
  }

  const inviteNewResident = () => {
    dispatch(fetchInviteeByEmail(inviteeEmail)).then((res) => {
      if (res?.data?.email) {
        const payload = {
          userId: res?.data?._id,
          zoneId: estateDetails.zone._id,
        };
        dispatch(addResident(payload)).then((res) => {
          if (res.status == 'Success') {
            setInviteeEmail('');
            setAddSingleResidentModal(false);
            dispatch(fetchResidents(estateDetails?.zone.estate?._id))
            toast.success("Resident has been added successfully.");
          }
        }).catch(() => {
          toast.error('Please try again later b')
        });
      } else {
        const payload = {
          estateId: estateDetails?.zone.estate?._id,
          email: inviteeEmail,
          inviteType: "resident",
          zoneId: estateDetails.zone._id,
        };
        dispatch(InviteResident(payload)).then((res) => {
          if (res.status == 'Success') {
            toast.success("An invitation have been successfully sent");
            setInviteeEmail('');
            setAddSingleResidentModal(false);
          }
        }).catch(() => {
          toast.error('Please try again later')
        });
      }
    })
  }

  return (
    <>
      <div className="residents-container overflow-hidden">
        <div className="d-flex justify-content-between align-items-center w-100 resident-overview-data">
          <h3 className=""> {residents?.length} Residents</h3>
          <div className="menu-items">
            <button className="button button-secondary">All Residents</button>
            <button className="button button-secondary">Filter</button>
            <Dropdown role="menu">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Add resident
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setAddSingleResidentModal(true)}>Add a single resident</Dropdown.Item>
                <Dropdown.Item onClick={() => alert('Feature Unavailable')}>Bulk Upload</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => setUploadResidentsModal(true)}>Bulk Upload</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {!loading && residents && residents.length > 0
          ? <div className="residents-table">
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Resident's name</th>
                  <th>Email address</th>
                  <th>Phone</th>
                  <th>Access code</th>
                </tr>
              </thead>
              <tbody>
                {residents?.map((resident, index) => {
                  if (resident?.user) return (
                    <tr onClick={() => openResidentDetails(resident)} key={resident?.user?._id}>
                      <td>{`${resident?.user?.first_name} ${resident?.user?.last_name}`}</td>
                      <td>{resident?.user?.email}</td>
                      <td>{`${resident?.user?.phone_number}`}</td>
                      <td className="status">
                        <span className={`${resident?.user?.active ? 'active' : 'disabled'}`}>
                          {resident?.user?.active ? 'Active' : 'Disabled'}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          : null
        }
        <div className="residents-table">
          {!loading && residents?.length == 0 ? <h1>No record found :(</h1> : null}
          {loading && !residents ? <h1>Loading...</h1> : null}
        </div>
      </div>
      <OffCanvas show={detailsOffCanvas} handleClose={() => setDetailsOffCanvas(false)}>
        <div className="side-panel-body-header" onClick={() => setDetailsOffCanvas(false)}>
          <img src={toAbsoluteUrl('/media/misc/arrow-back.svg')} className="item-icon" />
          <span>Back to Residents</span>
        </div>
        <div className="tabs">
          <button className={`${activeTab == 'info' && 'active'} tab`} onClick={() => setActiveTab('info')}>Info</button>
          <button className={`${activeTab == 'visitors' && 'active'} tab`} onClick={() => setActiveTab('visitors')}>Visitors</button>
        </div>
        {activeTab == 'info' &&
          <div className="infoo">
            <div className="user-info">
              <span className="name">{`${residentDetails?.user?.first_name} ${residentDetails?.user?.last_name}`}</span>
              <span className="access">
                <span>ESTATE ACCESS</span>
                <span className="status">
                  <span className={`${residentDetails?.isActive ? 'active' : 'disabled'}`}>
                    {residentDetails?.isActive ? 'Active' : 'Disabled'}
                  </span>
                </span>
              </span>
              {/* <span className="address">{residentDetails?.user?.address}</span> */}
            </div>
            <div className="others">
              <span className="sec-title">Other information</span>
              <span className="other d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/misc/phone.svg')} className="item-icon" />
                <span>{residentDetails?.user?.phone_number}</span>
              </span>
              <span className="other d-flex align-items-center">
                <img src={toAbsoluteUrl('/media/misc/email.svg')} className="item-icon" />
                <span>{residentDetails?.user?.email || 'support@estate.com'}</span>
              </span>
            </div>
            <div className="actions">
              <button className="a">Edit resident info</button>
              {/* <button className="b" onClick={() => [setDetailsOffCanvas(false), setRevokeAccessModal(true)]}>Revoke access</button> */}
              <button className="b" onClick={() => alert('Feature not available')}>Revoke access</button>
            </div>
            alert('Feature not available')
            {/* <button className="delete" onClick={() => [setDetailsOffCanvas(false), setDeleteResidentModal(true)]}>Delete resident from estate</button> */}
            <button className="delete" onClick={() => alert('Feature not available')}>Delete resident from estate</button>
          </div>}
        {activeTab == 'visitors' &&
          <div className="visitorrs">
            <div className="confirmed-passess">
              {accesses.map((access, index) => (
                <div className="d-flex align-items-start pass-details" key={`access-${index}`}>
                  <img src={toAbsoluteUrl('/media/misc/item-icon.png')} className="item-icon" />
                  <div className="details">
                    <span className="pass-type">{access.type}</span>
                    <span className="user">{access.user}</span>
                    <div className="d-flex align-items-center address">
                      <img src={toAbsoluteUrl('/media/misc/location.png')} className="data-icon" />
                      <span className="addr">{access.address}</span>
                    </div>
                    <div className="d-flex align-items-center time-access">
                      <div className="d-flex align-items-center">
                        <img src={toAbsoluteUrl('/media/misc/datetime.png')} className="data-icon" />
                        <span className="time">{access.datetime}</span>
                      </div>
                      <span className={`status ${access.status ? 'granted' : 'declined'}`}>
                        {access.status ? 'Access granted' : 'Access denied - Expired'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </OffCanvas>
      <AccessModal
        show={revokeAccessModal}
        title="Revoke estate access"
        continueText="Revoke Access"
        handleClose={() => setRevokeAccessModal(false)}
        handleContinue={() => setRevokeAccessModal(false)}
      >
        <p className="warning">You're about to revoke estate access for this resident;</p>
        <div className="user-info">
          <span className="name">{residentDetails?.name}</span>
          <span className="address">{residentDetails?.address}</span>
          <span>{residentDetails?.phone}</span>
          <span>{residentDetails?.email || 'support@estate.com'}</span>
        </div>
      </AccessModal>

      <AccessModal
        show={deleteResidentModal}
        title="Delete resident"
        handleClose={() => setDeleteResidentModal(false)}
        handleContinue={() => [setDeleteResidentModal(false), setDeleteResidentContinueModal(true)]}
      >
        <p className="warning">You're about to delete this resident from the estate. This action is not reversible.</p>
        <p className="warning">Do you want to continue?</p>
        <div className="user-info">
          <span className="name">{residentDetails?.name}</span>
          <span className="address">{residentDetails?.address}</span>
          <span>{residentDetails?.phone}</span>
          <span>{residentDetails?.email || 'support@estate.com'}</span>
        </div>
      </AccessModal>

      <AccessModal
        show={deleteResidentContinueModal}
        title="Delete resident"
        continueText="Delete resident"
        handleClose={() => setDeleteResidentContinueModal(false)}
        handleContinue={() => setDeleteResidentContinueModal(false)}
      >
        <p className="warning">You're about to delete this resident from the estate. This action is not reversible.</p>
        <p className="warning">Do you want to continue?</p>
        <div className="user-info mb-16">
          <input type="text" className="modal-input" placeholder="Enter comment" />
        </div>
      </AccessModal>

      <AccessModal
        show={addSingleResidentModal}
        title="Add a resident"
        continueText="Add a resident"
        continueType="primary"
        cancelType="plain"
        loading={loading}
        handleClose={() => setAddSingleResidentModal(false)}
        handleContinue={() => inviteNewResident()}
      >
        <div className="form-group">
          <input className="modal-input" placeholder="Email" type="email"
            value={inviteeEmail} onChange={(e) => setInviteeEmail(e.target.value)} />
        </div>
      </AccessModal>

      <AccessModal
        show={uploadResidentsModal}
        title="Upload residents"
        continueText="Add a resident"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setUploadResidentsModal(false)}
        handleContinue={() => setUploadResidentsModal(false)}
      >
        <div className="requirements">
          <h5>Requirements</h5>
          <ul>
            <li>The file must be CSV</li>
            <li>CSV file should contain the following columns  First name, Last name, Email, phone number, and House address</li>
            <li>The order of the columns should be same as the order in which they are listed above with the first row as headers.</li>
          </ul>
          <div className="upload">
            <label htmlFor="upload-file">
              <img src={toAbsoluteUrl('/media/misc/file-upload.svg')} className="item-icon" />
              Download a sample bulk CSV file
            </label>
            <input type="file" name="upload-file" id="upload-file" />
          </div>
        </div>
      </AccessModal>
    </>
  );
}

import axios from "axios";
import config from "../../../../config";

export const URL = config.URL;

export function postZone(payload) {
  return axios.post(`${URL}estates/create-zone`, payload);
}

export function putZone(payload, zoneId) {
  return axios.put(`${URL}estates/${zoneId}/update-zone`, payload);
}

export function getSingleZone(zoneId) {
  return axios.get(`${URL}estates/${zoneId}`);
}

export function getZoneActivityLogs(zoneId) {
  return axios.get(`${URL}estates/zones/${zoneId}/activity-logs`);
}

export function getEstateZones(zoneId) {
  return axios.get(`${URL}estates/${zoneId}/zones`);
}

import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/dashboard/Actions";

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import config from "../../../../config";
import * as functions from "../../../../globals/functions";
import { refresh_token } from "../../Auth/_redux/authCrud";
import { Container, Row, Col } from "react-bootstrap";
import './dashboard.scss';
import Moment from 'moment';
import _ from "lodash";

export function Dashboard() {

  const dispatch = useDispatch();

  const [residentPass, setResidentPass] = useState('')
  const [accessCode, setAccessCode] = useState(null);
  const [exp, setExp] = useState(null);


  const maxDate = new Date();
  const now = new Date();

  maxDate.setHours(maxDate.getHours() + 24);


  const { org, estate_codes, access_logs, loading } = useSelector(
    (state) => ({
      org: state.auth.organization,
      estate_codes: state.dashboard.estate_codes,
      access_logs: state.dashboard.access_logs,
      loading: state.dashboard.actionsLoading,
    }),
    shallowEqual
  );


  useEffect(() => {
    init_functions();
  }, [])

  function init_functions() {
    dispatch(actions.fetchAllData('estate_codes'));
    dispatch(actions.fetchAllData('estate_logs', org._id));
  }


  function generateCode() {
    var body = {};
    body['type'] = 'visitors';
    body['expiredAt'] = maxDate;
    dispatch(actions.addData('code', body));
  }


  function validateCode() {
    if (!residentPass) {
      return;
    }
    var body = {};
    body['code'] = residentPass;
    body['activityType'] = 'entry';
    dispatch(actions.addData('validate_code', body));
  }


  useEffect(() => {

    if (estate_codes) {

      var revMyArr = [].concat(estate_codes).reverse();

      const residentCodes = revMyArr.filter((item) => item.type === "visitors");
      if (estate_codes.length === 0 || residentCodes.length === 0) {
        generateCode();
      } else {
        setAccessCode(residentCodes[0].code);
        setExp(residentCodes[0].expiredAt);
      }
    }

  }, [estate_codes])



  // Function to format the access code as the user types
  const formatAccessCode = (input) => {
    // Remove any non-alphanumeric characters
    const formattedInput = input.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    // Add hyphen between the first 3 characters and the next 3 characters
    if (formattedInput.length > 3) {
      return (
        formattedInput.slice(0, 3) +
        '-' +
        formattedInput.slice(3, 6)
      );
    } else {
      return formattedInput;
    }
  };

  // Function to handle text input changes
  const handleTextChange = (text) => {
    // Format the input text
    const formattedText = formatAccessCode(text);
    // Update the state with the formatted text
    setResidentPass(formattedText);
  };



  return (
    <>
      <div className="flex-row-fluid d-flex flex-column overflow-hidden estate-dashboard">
        <div className="d-flex justify-content-between align-items-center estate-overview">
          <div className="estate-overview-data">
            <span className="labell">Estate</span>
            <span className="value">{_.truncate(org?.name, { 'length': 25, 'separator': ' '})}</span>
          </div>

          <div className="estate-overview-data">
            <span className="labell">Address</span>
            <span className="value">{_.truncate(org?.address, { 'length': 20, 'separator': ' '})}</span>
          </div>

          <div className="estate-overview-data">
            <span className="labell">Today's access</span>
            <span className="value">{accessCode || '...'} - <span className="date">{new Date(exp || now).toDateString()}</span></span>
          </div>
          {/* <div className="estate-overview-data"> 
            <span className="labell">Contractors pass</span>
            <span className="value">{estateDetails.contractorPass}</span>
          </div> */}
          <div className="estate-overview-data">
            <span className="labell expiry">Expires in: {exp ? functions.getTimeDifference(exp) : ''}</span>
            <button onClick={() => generateCode()}>{loading ? 'Generating...' : `Generate a new pass`}</button>
          </div>
        </div>
        <div className="row gx-5 passess">
          <div className="col-lg-6">
            <h3 className="section-title">Resident Pass</h3>
            <div className="d-flex aling-items-center justify-content-between verify-code">
              <input type="text" placeholder="Enter access code"
                value={residentPass} onChange={(e) => handleTextChange(e.target.value)} />
              <button type="submit" onClick={() => validateCode()} className="submit-pass">{loading ? 'Checking...' : 'Confirm'}</button>
            </div>
            <div className="confirmed-passess">
              {access_logs && access_logs.map((access, index) => (
                <div className="d-flex align-items-start pass-details">
                  <img src={toAbsoluteUrl('/media/misc/item-icon.png')} className="item-icon" />
                  <div className="details">
                    <span className="pass-type">{'Resident'}</span>
                    <span className="user">{access?.user ? `${access.user.first_name} ${access.user.last_name}` : ""}</span>
                    <div className="d-flex align-items-center address">
                      <img src={toAbsoluteUrl('/media/misc/location.png')} className="data-icon" />
                      <span className="addr">{access?.code?.zone?.name}</span>
                    </div>
                    <div className="d-flex align-items-center time-access">
                      <div className="d-flex align-items-center">
                        <img src={toAbsoluteUrl('/media/misc/datetime.png')} className="data-icon" />
                        <span className="time">{Moment(access.activityAt).format("MMM DD, HH:mm")}</span>
                      </div>
                      <span className={`status ${access.activityType === 'entry' ? 'granted' : 'declined'}`}>
                        {access.activityType === 'entry' ? 'Access granted' : 'Exit pass'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 left-col">
            <h3 className="section-title">Contractors Pass</h3>
            <div className="d-flex aling-items-center justify-content-between verify-code">
              <input type="text" placeholder="Enter access code"
                // value={residentPass}
                onChange={(e) => setResidentPass(e.target.value)} />
              <button className="submit-pass">Confirm</button>
            </div>
            {/* <div className="confirmed-passess">
              {accesses.map((access, index) => (
                <div className="d-flex align-items-start pass-details">
                  <img src={toAbsoluteUrl('/media/misc/item-icon.png')} className="item-icon" />
                  <div className="details">
                    <span className="pass-type">Contractor</span>
                    <span className="user">{access.user}</span>
                    <div className="d-flex align-items-center address">
                      <img src={toAbsoluteUrl('/media/misc/location.png')} className="data-icon" />
                      <span className="addr">{access.address}</span>
                    </div>
                    <div className="d-flex align-items-center time-access">
                      <div className="d-flex align-items-center">
                        <img src={toAbsoluteUrl('/media/misc/datetime.png')} className="data-icon" />
                        <span className="time">{access.datetime}</span>
                      </div>
                      <span className={`status ${access.status ? 'granted' : 'declined'}`}>
                        {access.status ? 'Access granted' : 'Access denied - Pass expired'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </>);
}

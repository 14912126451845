import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  listLoading: false,
  actionsLoading: false,
  userDetails: null,
  estateDetails: null,
  estateUsers: null,
  inviteeDetails: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const SettingsSlice = createSlice({
  name: "settings",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    disableLoad: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
    },

    userData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.userDetails = action.payload;
    },
    estateData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.estateDetails = action.payload;
    },
    estateUsers: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.estateUsers = action.payload;
    },
    inviteeDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.inviteeDetails = action.payload;
    },
  }
});

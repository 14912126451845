import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  listLoading: false,
  actionsLoading: false,
  zoneDetails: null,
  zoneActivityLogs: null,
  estateZones: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ZonesSlice = createSlice({
  name: "zones",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    disableLoad: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
    },
    zoneDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.zoneDetails = action.payload;
    },
    estateZones: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.estateZones = action.payload;
    },
    zoneActivityLogs: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.actionsLoading = false;
      state.zoneActivityLogs = action.payload;
    },

  }
});

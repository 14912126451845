import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchEstateProfile } from '../../Settings/_redux/Actions';
import { fetchEstateZones } from '../_redux/Actions';
import './zones.scss';

export function Zones(props) {
  const { navigation } = props;
  const dispatch = useDispatch();


  const { loading, estateDetails, estateZones } = useSelector(
    (state) => ({
      loading: state.settings.listLoading,
      estateDetails: state.settings.estateDetails,
      estateZones: state.zones.estateZones,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEstateProfile());
  }, []);

  useEffect(() => {
    if (estateDetails && estateDetails?.zone) {
      dispatch(fetchEstateZones(estateDetails?.zone.estate?._id,));
    }
  }, [estateDetails])

  return (
    <>
      <div className="zones-container overflow-hidden">
        <div className="d-flex justify-content-between align-items-center w-100 zones-overview-data">
          <h3 className=""> {estateZones?.length} Zones</h3>
          <div className="menu-items">
            <button className="button button-secondary">Filter</button>
            <button className="button button-primary" onClick={() => props.history.push('/zones/add/new')}>Add Zone</button>
          </div>
        </div>
        {!loading && estateZones && estateZones.length > 0
          ? <div className="residents-table">
            <Table responsive hover>
              <thead>
                <tr>
                  <th style={{ width: '18%' }}>Zone's name</th>
                  <th style={{ width: '18%' }}>Description</th>
                </tr>
              </thead>
              <tbody>
                {estateZones?.map((zone, index) => (
                  <tr key={`${zone?.name}-${index}`} onClick={() => props.history.push(`/zones/${zone?._id}`)}>
                    <td>{zone.name}</td>
                    <td>{zone?.description || 'n/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          : null
        }
        <div className="residents-table">
          {loading && !estateZones ? <h1>Loading...</h1> : null}
          {!loading && estateZones?.length == 0 ? <h1>No record found :(</h1> : null}
        </div>
      </div>
    </>
  );
}

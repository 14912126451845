import React, { useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useSelector, shallowEqual } from 'react-redux';

export function Routes() {
    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user && auth.organization != null,
        }),
        shallowEqual
    );

    // const [isAuthorized, setIsAuthorized] = useState(true)

    return (
        <Switch>
            {!isAuthorized
                ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage />
                    </Route>
                )
                : (
                    /*Otherwise redirect to root page (`/`)*/
                    <Redirect from="/auth" to="/" />
                )}

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}

import axios from "axios";
import config from "../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(type, data, id) {
  // return axios.post(URL, { data });
  if (type === 'assign') {
    return axios.post(`${URL}dangers/${id}/assign-agent`, data);
  }
}

export function getUserProfile(userId) {
  return axios.get(`${URL}/user/${userId}`);
}

export function getEstateProfile() {
  return axios.get(`${URL}/estates/users/estate-profile`);
}

export function getEstateUsers(estateId) {
  return axios.get(`${URL}estates/${estateId}/users`);
}

export function getUserByEmail(email) {
  return axios.get(`${URL}users/phone-number-email?email=${email}`);
}

export function sendUserInvite(payload) {
  return axios.post(`${URL}estates/invite-user`, payload);
}

export function addUserToEstate(payload) {
  return axios.post(`${URL}estates/users/attach`, payload);
}

export function putUserInfo(payload) {
  return axios.put(`${URL}users`, payload);
}

export function putEstateInfo(payload, estateId) {
  return axios.put(`${URL}estates/${estateId}/update`, payload);
}

export function passwordChangeInit(payload) {
  return axios.post(`${URL}auth/forgot-password`, payload);
}

export function passwordChangeFinal(payload) {
  return axios.post(`${URL}auth/reset-password`, payload);
}

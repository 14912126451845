import React from 'react'
import { Table } from 'react-bootstrap'

export default function Users({ estateUsers, setChangeRoleModal, setRemoveUserModal, setAddUserModal }) {
  return (
    <div className="users">
      <div className="d-flex justify-content-between align-items-center mb-8">
        {estateUsers && estateUsers.length
          ? <h6>{estateUsers.length} users</h6>
          : <h1>No record found :( </h1>
        }
        <button onClick={() => setAddUserModal(true)}>
          Invite user
        </button>
      </div>
      {estateUsers && estateUsers.length > 0
        ? <div className="settings-table">
          <Table responsive hover>
            <thead>
              <tr>
                <th>Agent's name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Access</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {estateUsers?.map((euser, index) => (
                <tr key={index}>
                  <td>{`${euser?.user?.first_name} ${euser?.user?.last_name}`}</td>
                  <td>{euser?.user?.email}</td>
                  <td>{`${euser?.user?.phone_number}`}</td>
                  <td className="status">
                    <span className={`${euser?.user?.active ? 'active' : 'disabled'}`}>
                      {euser?.user?.active ? 'Active' : 'Disabled'}
                    </span>
                  </td>
                  <td className="row-action">
                    {/* <button className="plain" onClick={() => setChangeRoleModal(true)}>Change role</button> */}
                    <button className="remove" onClick={() => alert('Feature unavailable')}>Remove</button>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        : null}
    </div>
  )
}

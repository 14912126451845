import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import { Residents } from "./main";
import { CustomersUIProvider } from "./UIContext";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/Actions";


export function ResidentPage({ history }) {
  const dispatch = useDispatch();

  const { org } = useSelector(
    (state) => ({
      org: state.auth.organization,
      entities: state.alert.active_danger ? state.alert.active_danger.data : []
    }),
    shallowEqual
  );

  return (
    <CustomersUIProvider customersUIEvents={{}}>
      <CustomersLoadingDialog />
      <Switch>
        <Route path="/residents" exact>
          {({ history, match }) => (
            <Residents
              show={match != null}
              history={history}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route>
      </Switch>
    </CustomersUIProvider>
  );
}

import axios from "axios";
import config from "../../../../config";

export const URL = config.URL;

export function sendInvite(payload) {
  return axios.post(`${URL}estates/invite-user`, payload);
}

export function getResidents(estateId) {
  return axios.get(`${URL}estates/${estateId}/residents`);
}

export function getUserByEmail(email) {
  return axios.get(`${URL}users/phone-number-email?email=${email}`);
}

export function addResidentsToEstate(payload) {
  return axios.post(`${URL}estates/residents/attach`, payload);
}
import React, { useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { Table, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchZoneDetails, fetchZoneLogs } from '../_redux/Actions';
import moment from 'moment';
import './zones.scss'


const history = [
  {
    name: 'Dozie Mide',
    passed: 'Visitor',
    address: '2 James Harvey Avenue...',
    datetime: 'Aug 13 2020 2:21 PM',
  },
  {
    name: 'Dozie Mide',
    passed: 'Visitor',
    address: '2 James Harvey Avenue...',
    datetime: 'Aug 13 2020 2:21 PM',
  },
  {
    name: 'Dozie Mide',
    passed: 'Visitor',
    address: '2 James Harvey Avenue...',
    datetime: 'Aug 13 2020 2:21 PM',
  },
  {
    name: 'Dozie Mide',
    passed: 'Visitor',
    address: '2 James Harvey Avenue...',
    datetime: 'Aug 13 2020 2:21 PM',
  },
]
export default function ZoneDetails(props) {
  const dispatch = useDispatch();
  let { zoneId } = useParams();

  const { loading, zoneDetails, zoneActivityLogs } = useSelector(
    (state) => ({
      loading: state.zones.listLoading,
      zoneDetails: state.zones.zoneDetails,
      zoneActivityLogs: state.zones.zoneActivityLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (zoneId) {
      dispatch(fetchZoneDetails(zoneId));
    }
  }, [zoneId])

  useEffect(() => {
    if (zoneId && !zoneActivityLogs) {
      dispatch(fetchZoneLogs(zoneId));
    }
  }, [zoneId, zoneActivityLogs])

  return (
    <div className='zones-container zone-details'>
      <div className='upper'>
        <h3>{!loading && zoneDetails && zoneDetails?.name ? zoneDetails.name : 'Loading...'}</h3>
        {zoneDetails && zoneDetails?.name
          ? <div className="menu-items">
            <Dropdown role="menu">
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                Zone options
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => props.history.push(`/zones/edit/${zoneId}`, { data: zoneDetails })}>Edit Zone</Dropdown.Item>
                <Dropdown.Item onClick={() => alert('Feature unavailable')}>Disable Zone</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          : null}
      </div>
      <div className='backtozones'>
        <span onClick={() => props.history.goBack()}> {`< Back to Zones`}</span>
      </div>
      {/* {!loading && zoneDetails && zoneDetails?.name
        ? <div className='zonedata'>
          <h2>Zone information</h2>
          <div className='desc'>
            <h5 className='desc-label'>Description</h5>
            <p className='desc-desc'>{zoneDetails.desc}</p>
          </div>
          <div className='overview'>
            <div className='zone-data'>
              <span className="z-label">Zone name</span>
              <span className="z-value">{zoneDetails.name}</span>
            </div>
            <div className='zone-data'>
              <span className="z-label">Estate</span>
              <span className="z-value">{zoneDetails.estate}</span>
            </div>
            <div className='zone-data'>
              <span className="z-label">Location</span>
              <span className="z-value">{zoneDetails.location}</span>
            </div>
            <div className='zone-data'>
              <span className="z-label">Country</span>
              <span className="z-value">{zoneDetails.country}</span>
            </div>
          </div>
        </div>
        : <div className='zonedata'> <h1>Zone information not found :( </h1> </div>
      } */}
      <div className='history'>
        <h2>Access History</h2>
        {!loading && zoneActivityLogs && zoneActivityLogs.length > 0
          ? <div className="residents-table">
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {zoneActivityLogs?.map((data, index) => {
                  if (data?.code && data?.user) {
                    return (
                      <tr key={`${data?.name}-${index}`}>
                        <td>{`${data.user?.last_name || 'n/A'} ${data.user?.first_name || 'n/A'}`}</td>
                        <td>{data.user?.phone_number || 'n/A'}</td>
                        <td>{moment(data.activityAt).format('DD MMM YYYY HH:mm A')}</td>
                        <td>{data?.activityType?.toUpperCase() || 'n/A'}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </Table>
          </div>
          : null
        }
        <div className="residents-table">
          {!loading && zoneActivityLogs?.length == 0 ? <h1>No record found :(</h1> : null}
          {loading && !zoneActivityLogs ? <h1>Loading...</h1> : null}
        </div>
      </div>
    </div>
  )
}


import { toast } from 'react-toastify';
import * as requestFromServer from "./Crud";
import { ResidentsSlice, callTypes } from "./Slice";

const { actions } = ResidentsSlice;


export const InviteResident = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .sendInvite(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const addResident = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .addResidentsToEstate(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't invite user";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchResidents = (estateId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getResidents(estateId)
    .then(response => {
      var res = response.data;
      dispatch(actions.residentsData(res.data.residents || null));
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInviteeByEmail = (email) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getUserByEmail(email)
    .then(response => {
      var res = response.data;
      dispatch(actions.inviteeDetails(res.data || null));
      return res;
    })
    .catch(error => {
      error.clientMessage = "Can't find User";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
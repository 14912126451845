import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { DashboardSlice } from "../app/modules/Dashbord/_redux/dashboard/Slice";
import { AlertSlice } from "../app/modules/Incidents/_redux/incidents/Slice";
import { AccountSlice } from "../app/modules/Agents/_redux/accounts/Slice";
import { SettingsSlice } from '../app/modules/Settings/_redux/Slice';
import { ResidentsSlice } from "../app/modules/Residents/_redux/Slice";
import { ZonesSlice } from "../app/modules/Zones/_redux/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: DashboardSlice.reducer,
  alert: AlertSlice.reducer,
  accounts: AccountSlice.reducer,
  settings: SettingsSlice.reducer,
  residents: ResidentsSlice.reducer,
  zones: ZonesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}

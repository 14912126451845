import React from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import { Zones } from "./main";
import ZoneDetails from "./zone";
import EditZone from './editZone';
import { CustomersUIProvider } from "./UIContext";


export function ZonesPage() {
  return (
    <CustomersUIProvider customersUIEvents={{}}>
      <CustomersLoadingDialog />
      <Switch>
        <Route path="/zones" exact>
          {({ history, match }) => (
            <Zones
              show={match != null}
              history={history}
              onHide={() => {
                history.push("/");
              }}
            />
          )}
        </Route>
        <Route exact path="/zones/:zoneId">
          {({ history, match }) => (
            <ZoneDetails
              show={match != null}
              id={match && match.params.id}
              history={history}
              onHide={() => {
                history.goBack();
              }}
            />
          )}
        </Route>
        <Route exact path="/zones/add/new">
          {({ history, match }) => (
            <EditZone
              show={match != null}
              id={match && match.params.id}
              history={history}
              onHide={() => {
                history.goBack();
              }}
            />
          )}
        </Route>
        <Route exact path="/zones/edit/:id">
          {({ history, match }) => (
            <EditZone
              show={match != null}
              id={match && match.params.id}
              history={history}
              onHide={() => {
                history.goBack();
              }}
            />
          )}
        </Route>
      </Switch>
    </CustomersUIProvider>
  );
}

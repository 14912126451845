import React, { Suspense, lazy, useState, useRef, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashbord/pages";
import { ResidentPage } from './modules/Residents/pages';
import { ZonesPage } from "./modules/Zones/pages";
import { AccessCodePage } from './modules/AccessCodes/pages';
import { SettingsPage } from './modules/Settings/pages'
import { shallowEqual, useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import * as actions from "../app/modules/Incidents/_redux/incidents/Actions";

import Marker from "../_metronic/layout/components/marker";
import Notification from 'react-web-notification';
import useSound from 'use-sound';
import boopSfx from '../sound/sound.mp3';
import config from "../config";
var Ably = require('ably');

const AgentsRoots = lazy(() =>
  import("./modules/Agents/pages")
);

const UserRoots = lazy(() =>
  import("./modules/User/pages")
);

const SettingsRoot = lazy(() =>
  import("./modules/Settings/pages")
);

export default function BasePage() {
  const clickRef = useRef();
  const dispatch = useDispatch();

  const { user, org, ably } = useSelector(
    (state) => ({
      user: state.auth.user,
      org: state.auth.organization,
      ably: state.alert.ably
    }),
    shallowEqual
  );

  const [play] = useSound(boopSfx);
  const [options, setOptions] = useState({});
  const [ignore, setIgnore] = useState(false);
  const [title, setTitle] = useState('');

  const handleButtonClick = () => {
    if (!user) {
      return;
    }
    // clickRef.current.click()
    setTitle('New Request')
    setOptions({
      tag: Date.now(),
      body: `${user.first_name} attend to this new request`
    })
  }

  useEffect(() => {
    if (!ably) {
      // setupReal()
    }
  }, [ably])

  function setupReal() {
    var client = new Ably.Realtime(config.ABLY_KEY);
    dispatch(actions.sendAb(client));
    client.connection.on('connected', function () {
      var channel = client.channels.get(config.ABLY_CHANNEL_NAME);
      channel.subscribe(user.user_id, function (message) {
        dispatch(actions.fetchAllData('pending_danger', org._id));
        dispatch(actions.fetchAllData('active_danger', org._id));
        handleButtonClick();
      });
    });
  }



  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Notification
        ignore={ignore}
        timeout={5000}
        title={title}
        options={options}
      />
      <a ref={clickRef}
        onClick={() => play()}
        href="javascript:;"
        style={{ display: 'none' }}
      ></a>


      <Switch>

        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/residents" component={ResidentPage} />
        <Route path="/access" component={AccessCodePage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/zones" component={ZonesPage} />
        <Route path="/settings" component={SettingsRoot} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

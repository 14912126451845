import React from 'react'

export default function General({ loading, generalFields, handleGeneralForm, setChangePasswordEmailModal, editInfo }) {
  return (
    <div className="settings-form">
      <h6>Personal Information</h6>
      <div className="form-content">
        <div className="fields">
          <div className="form-group">
            <input className="modal-input" placeholder="First name" type="text"
              value={generalFields.firstname} onChange={(e) => handleGeneralForm('firstname', e.target.value)} />
            <input className="modal-input" placeholder="Last name" type="text"
              value={generalFields.lastname} onChange={(e) => handleGeneralForm('lastname', e.target.value)} />
          </div>
          <div className="form-group">
            <input className="modal-input" placeholder="Email" type="email" disabled
              value={generalFields.email} onChange={(e) => handleGeneralForm('email', e.target.value)} />

            <input className="modal-input" placeholder="Phone" type="phone" disabled
              value={generalFields.phone} onChange={(e) => handleGeneralForm('phone', e.target.value)} />
          </div>
        </div>
      </div>
      <div className="submit-changes">
        <button className="plain" onClick={() => setChangePasswordEmailModal(true)}>Change password</button>
        <button className="primary" onClick={editInfo}>{loading ? 'Please wait...' : 'Save'}</button>
      </div>
    </div>
  )
}
 
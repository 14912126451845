import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { createZone, editZone } from '../_redux/Actions';
import { fetchEstateProfile } from '../../Settings/_redux/Actions';
import { fetchZoneDetails } from '../_redux/Actions';
import './zones.scss'


export default function EditZone(props) {
  const dispatch = useDispatch();
  let { id } = useParams()
  const pathname = props?.history?.location?.pathname;
  const [zoneFields, setZoneFields] = useState({});

  const { loading, estateDetails, zoneDetails } = useSelector(
    (state) => ({
      loading: state.zones.listLoading,
      estateDetails: state.settings.estateDetails,
      zoneDetails: state.zones.zoneDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!zoneDetails) {
      dispatch(fetchZoneDetails(id));
    }
    if (zoneDetails && zoneDetails?.name) {
      handleZoneForm('name', zoneDetails.name)
    }
  }, [zoneDetails])

  useEffect(() => {
    if (!estateDetails || !estateDetails?.zone) {
      dispatch(fetchEstateProfile());
    }
  }, [estateDetails])

  const handleZoneForm = (field, value) => {
    const zoneClone = { ...zoneFields };
    zoneClone[field] = value;
    setZoneFields(zoneClone);
  }

  const submitZone = () => {
    if (!zoneFields.name) {
      return alert('All fields are compulsory!')
    }
    if (pathname.includes('new')) {
      const payload = {
        name: zoneFields.name,
        estateId: estateDetails?.zone?.estate?._id,
        // description: zoneFields.description,
      }
      dispatch(createZone(payload)).then((res) => {
        if (res?.status == "Success") {
          props.history.push('/zones')
        } else {
          alert(res?.message || 'Unsuccessful. Please retry later.')
        }
      });
      return;
    }
    if (pathname.includes('edit') && id) {
      const payload = {
        name: zoneFields.name,
      }
      dispatch(editZone(payload, zoneDetails?._id)).then((res) => {
        if (res?.status == "Success") {
          props.history.push('/zones')
        } else {
          alert(res?.message || 'Unsuccessful. Please retry later.')
        }
      });
    }
  }

  return (
    <div className='zones-container add-zone'>
      <div className='upper'>
        <h2>{id ? 'Edit' : 'Add'} a zone</h2>
      </div>
      <div className='zone-form'>
        <h2>Zone information</h2>
        <div className="form-group">
          <input className="input-field" placeholder="Name" type="text"
            value={zoneFields.name} onChange={(e) => handleZoneForm('name', e.target.value)} />
          {/* <input className="input-field" placeholder="Description" type="text"
            value={zoneFields.description} onChange={(e) => handleZoneForm('description', e.target.value)} /> */}
        </div>
        <div className="submit-changes">
          <button className="button-secondary mr-4" onClick={() => props.history.goBack(-1)}>Cancel</button>
          <button className="primary" onClick={submitZone} disabled={loading}>
            {loading ? 'Please wait...' : 'save'}
          </button>
        </div>
      </div>
    </div>
  )
}


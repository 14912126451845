import * as requestFromServer from "./Crud";
import { ZonesSlice, callTypes } from "./Slice";

const { actions } = ZonesSlice;


export const createZone = (payload) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .postZone(payload)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Error occured";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const editZone = (payload, zoneId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .putZone(payload, zoneId)
    .then(response => {
      var res = response.data;
      return res;
    })
    .catch(error => {
      error.clientMessage = "Error occured";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchZoneDetails = (zoneId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSingleZone(zoneId)
    .then(response => {
      var res = response.data;
      if (res.data && res.data.name) {
        dispatch(actions.zoneDetails(res.data || null));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Error occured";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const fetchZoneLogs = (zoneId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getZoneActivityLogs(zoneId)
    .then(response => {
      var res = response.data;
      console.log('res.data:ASSALAS:ASAS:AS:SA:', res?.data?.logs);
      if (res?.data?.logs) {
        dispatch(actions.zoneActivityLogs(res.data.logs || null));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Error occured";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEstateZones = (zoneId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getEstateZones(zoneId)
    .then(response => {
      var res = response.data;
      if (res.data) {
        dispatch(actions.estateZones(res.data || null));
      }
      return res;
    })
    .catch(error => {
      error.clientMessage = "Error occured";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
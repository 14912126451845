import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/_helpers'

export default function Payment({ setAddCardInitModal }) {
  return (
    <div className="cards">
      <div className="d-flex justify-content-between align-items-center mb-8">
        <h6>Payment infromation</h6>
        <button onClick={() => setAddCardInitModal(true)}>
          Add card
        </button>
      </div>
      <div className="cards-content">
        {Array(3).fill('x').map((_, index) => (
          <div className="debit-card" key={index}>
            <span className="debit-card-number">**** 0293</span>
            {index % 2 ? <span className="debit-card-default">Default</span> : null}
            <div className="debit-card-base">
              <div className="expiry">
                <span>Expires</span>
                <span className="exp-date">Aug 2024</span>
              </div>
              <img src={toAbsoluteUrl('/media/misc/visa.svg')} className="card-type" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

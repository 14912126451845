import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/Actions";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import './access.scss';
import AccessModal from "../../../../_metronic/layout/components/AccessModal";
import OffCanvas from "../../../../_metronic/layout/components/OffCanvas";

const accesses = [
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: false,
  },
  {
    type: 'Resident',
    user: 'Odetola Azeez',
    address: '41 Akinwunmi street, Mende',
    datetime: '12:22 PM',
    status: true,
  },
]

const codes = [
  { code: '1D3-456', for: 'Resident', status: 'active', datetime: 'Aug 13 2020 2:21 PM', usageCount: 39 },
  { code: '1D3-456', for: 'Contractors', status: 'active', datetime: 'Aug 13 2020 2:21 PM', usageCount: 339 },
  { code: '1D3-456', for: 'Resident', status: 'expired', datetime: 'Aug 13 2020 2:21 PM', usageCount: 90 },
  { code: '1D3-456', for: 'Resident', status: 'expired', datetime: 'Aug 13 2020 2:21 PM', usageCount: 44 },
  { code: '1D3-456', for: 'Resident', status: 'expired', datetime: 'Aug 13 2020 2:21 PM', usageCount: 12 },
  { code: '1D3-456', for: 'Contractors', status: 'active', datetime: 'Aug 13 2020 2:21 PM', usageCount: 74 },
]

export function Residents(props) {

  const dispatch = useDispatch();
  const [key, setKey] = useState("active");
  const [detailsOffCanvas, setDetailsOffCanvas] = useState(false);
  const [generateCodeModal, setGenerateCodeModal] = useState(false);
  const [deleteResidentModal, setDeleteResidentModal] = useState(false)
  const [timedCode, setTimedcode] = useState(false);
  const [deleteResidentContinueModal, setDeleteResidentContinueModal] = useState(false);
  const [activeTab, setActiveTab] = useState('info');
  const [addSingleResidentModal, setAddSingleResidentModal] = useState(false);
  const [uploadResidentsModal, setUploadResidentsModal] = useState(false);
  const [formFields, setFormFields] = useState({});
  const [estateDetails, setEstateDetails] = useState({
    name: 'Ogudu GRA',
    accesses: 5234,
    accessDate: '23 May, 2023',
    contractorPass: '1D3-456',
    expiry: '23:13:41',
  });
  const [codeDetails, setCodeDetails] = useState(codes[0]);

  const openResidentDetails = (data) => {
    setCodeDetails(data);
    setDetailsOffCanvas(true);
  }

  const { pending_danger } = useSelector(
    (state) => ({
      org: state.auth.organization,
      active_danger: state.alert.active_danger,
      pending_danger: state.alert.pending_danger,
    }),
    shallowEqual
  );

  const maxString = (str, lgt) => {
    let res = str;
    if (str.length <= lgt) {
      return res
    }
    else {
      const strArr = str.split('');
      strArr.length = lgt;
      res = `${strArr.join('')}...`;
    }
    return res;
  }

  const handleChange = (field, value) => {
    const oldform = { ...formFields };
    oldform[field] = value;
    setFormFields(oldform);
  }

  return (
    <>
      <div className="access-container overflow-hidden">
        <div className="d-flex justify-content-between align-items-center w-100 resident-overview-data">
          <h3 className=""> {codes?.length} Access codes</h3>
          <div className="menu-items">
            <button className="button button-secondary">All pass codes</button>
            <button className="button button-secondary">Filter</button>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center estate-overview">
          <div className="estate-overview-data">
            <span className="labell">Today's access</span>
            <span className="value">{estateDetails.accessDate}</span>
          </div>
          <div className="grouped-pass">
            <div className="estate-overview-data">
              <span className="labell">Visitoes code</span>
              <span className="value">{estateDetails.contractorPass}</span>
            </div>
            <div className="estate-overview-data">
              <span className="labell expiry">Expires in: {estateDetails.expiry}</span>
              <button onClick={() => setGenerateCodeModal(true)}>Generate a new pass</button>
            </div>
          </div>
          <div className="grouped-pass">
            <div className="estate-overview-data">
              <span className="labell">Contractors code</span>
              <span className="value">{estateDetails.contractorPass}</span>
            </div>
            <div className="estate-overview-data">
              <span className="labell expiry">Expires in: {estateDetails.expiry}</span>
              <button onClick={() => setGenerateCodeModal(true)}>Generate a new pass</button>
            </div>
          </div>
        </div>

        <div className="residents-table">
          <Table responsive hover>
            <thead>
              <tr>
                <th>Code</th>
                <th>For</th>
                <th>Status</th>
                <th>Date generated</th>
              </tr>
            </thead>
            <tbody>
              {codes?.map((code, index) => (
                <tr onClick={() => openResidentDetails(code)}>
                  <td>{code.code}</td>
                  <td>{code.for}</td>
                  <td className="status">
                    <span className={`${code.status == 'active' ? 'active' : 'disabled'}`}>
                      {code.status == 'active' ? 'Active' : 'Expired'}
                    </span>
                  </td>
                  <td>{code.datetime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <OffCanvas show={detailsOffCanvas} handleClose={() => setDetailsOffCanvas(false)}>
        <div className="side-panel-body-header" onClick={() => setDetailsOffCanvas(false)}>
          <img src={toAbsoluteUrl('/media/misc/arrow-back.svg')} className="item-icon" />
          <span>Back to Pass codes</span>
        </div>
        <div className="tabs">
          <button className={`${activeTab == 'info' && 'active'} tab`} onClick={() => setActiveTab('info')}>Info</button>
          <button className={`${activeTab == 'usage' && 'active'} tab`} onClick={() => setActiveTab('usage')}>Usage</button>
        </div>
        {activeTab == 'info' &&
          <div className="infoo">
            <div className="user-info">
              <span className="code">{codeDetails?.code}</span>
              <span className="access">
                <span>Resident's code</span>
                <span className="status">
                  <span className={`${codeDetails.status == 'active' ? 'active' : 'disabled'}`}>
                    {codeDetails.status == 'active' ? 'Active' : 'Disabled'}
                  </span>
                </span>
              </span>
            </div>
            <div className="others">
              <span className="other">
                <span className="lbl">Date generated</span>
                <span className="value">{codeDetails?.datetime}</span>
              </span>
              <span className="other">
                <span className="lbl">Usage</span>
                <span className="value">{codeDetails?.usageCount} access</span>
              </span>
              <span className="other">
                <span className="lbl">Pass expires</span>
                <span className="value expiry">23:13:41</span>
              </span>
            </div>
          </div>}
        {activeTab == 'usage' &&
          <div className="visitorrs">
            <div className="confirmed-passess">
              {accesses.map((access, index) => (
                <div className="d-flex align-items-start pass-details">
                  <img src={toAbsoluteUrl('/media/misc/item-icon.png')} className="item-icon" />
                  <div className="details">
                    <span className="pass-type">{access.type}</span>
                    <span className="user">{access.user}</span>
                    <div className="d-flex align-items-center address">
                      <img src={toAbsoluteUrl('/media/misc/location.png')} className="data-icon" />
                      <span className="addr">{access.address}</span>
                    </div>
                    <div className="d-flex align-items-center time-access">
                      <div className="d-flex align-items-center">
                        <img src={toAbsoluteUrl('/media/misc/datetime.png')} className="data-icon" />
                        <span className="time">{access.datetime}</span>
                      </div>
                      <span className={`status ${access.status ? 'granted' : 'declined'}`}>
                        {access.status ? 'Access granted' : 'Access denied - Expired'}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </OffCanvas>

      <AccessModal
        show={generateCodeModal}
        title="Generate a new code"
        continueText="Generate a new pass"
        continueType="primary"
        cancelType="plain"
        handleClose={() => setGenerateCodeModal(false)}
        handleContinue={() => setGenerateCodeModal(false)}
      >
        <div className="codes">
          <p className="warning">Generating a new code will disable the current pass.</p>
          <div className="info">
            <span className="code">{codeDetails?.code}</span>
          </div>
          <button onClick={() => setTimedcode(!timedCode)}>
            {timedCode
              ? <img src={toAbsoluteUrl('/media/misc/checked.svg')} className="item-icon" />
              : <img src={toAbsoluteUrl('/media/misc/ring.svg')} className="item-icon" />
            }
            <span>This is a temporary code</span>
          </button>
          {timedCode ?
            <button className="timeline">
              <img src={toAbsoluteUrl('/media/misc/datetime.svg')} className="item-icon" />
              <span>Thursday, August 10  3:00pm - 4:00pm</span>
            </button>
            : null}
        </div>
      </AccessModal>
    </>
  );
}
